import React from "react";
import { autobind } from "react-decoration";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import cateAPI from "lib/cateAPI";

@inject("appStore")
@observer
class DetailCateComp2 extends React.Component {
  constructor(props) {
    super(props);
    let isAdded = 0;
    let { cateData } = props;
    if (cateData !== null && cateData.length !== 0) {
      isAdded = 1;
    }
    this.state = {
      isAdded: isAdded,
      showAddCate: false,
      cateName: "",
    };
  }

  @autobind
  handleCateName(e) {
    this.setState({
      cateName: e.target.value,
    });
  }

  @autobind
  addCate(e) {
    e.preventDefault();
    let ids = [];
    ids.push(this.props.id);
    if (this.props.readerStore.auth) {
      cateAPI
        .addCate(this.props.id, this.props.resourceType, this.state.cateName)
        .then((data) => {
          if (data !== null && data !== undefined) {
            if (data.data.mutation.success) {
              this.setState({
                showAddCate: false,
                isAdded: 1,
                cateName: "",
              });
            }
          }
        });
    } else {
      this.props.appStore.openLogin();
    }
  }

  @autobind
  delCate(e) {
    e.preventDefault();
    let ids = [];
    ids.push(this.props.id);
    if (this.props.readerStore.auth) {
      cateAPI.delCate(this.props.id, this.props.resourceType).then((data) => {
        if (data !== null && data !== undefined) {
          if (data.data.mutation.success) {
            this.setState({
              showAddCate: false,
              isAdded: 0,
            });
          }
        }
      });
    } else {
      this.props.appStore.openLogin();
    }
  }

  render() {
    let { isAdded } = this.state;
    return (
      <>
        {isAdded === 0 && (
          <a
            tabIndex="0"
            id={"addCate_" + this.props.id}
            onClick={(e) => {
              e.preventDefault();
              if (this.props.readerStore.auth) {
                this.setState({ showAddCate: true });
              } else {
                this.props.appStore.openLogin();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (this.props.readerStore.auth) {
                  this.setState({ showAddCate: true });
                } else {
                  this.props.appStore.openLogin();
                }
              }
            }}>
            <img
              src="/file/images/tag_g.png"
              alt={this.props.t("jumperrwd.common.addfavorites")}
              title={this.props.t("jumperrwd.common.addfavorites")}
            />
          </a>
        )}
        {isAdded !== 0 && (
          <a
            tabIndex="0"
            id={"delCate_" + this.props.id}
            onClick={this.delCate}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.delCate(e);
              }
            }}>
            <img
              src="/file/images/tag_r.png"
              alt={this.props.t("jumperrwd.common.delfavorites")}
              title={this.props.t("jumperrwd.common.delfavorites")}
            />
          </a>
        )}
        {this.state.showAddCate && (
          <div id="showCate" className="showall">
            <input
              type="text"
              id={"cate_name_" + this.props.id}
              name={"cate_name_" + this.props.id}
              title
              onChange={this.handleCateName}
              value={this.state.cateName}
            />
            <input
              type="button"
              tabIndex="0"
              onClick={this.addCate}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.addCate(e);
                }
              }}
              className="btn"
              value={this.props.t("hyint.common.table.add")}
            />
          </div>
        )}
      </>
    );
  }
}

export default DetailCateComp2;
