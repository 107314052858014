import React from "react";
import qs from "query-string";

class IntegrationSearchDBInfo extends React.Component {
  constructor(props) {
    super(props);

  }

  render(){
    return ""
  }

}

IntegrationSearchDBInfo.propTypes = {
  
};

IntegrationSearchDBInfo.defaultProps = {
  
};

export default IntegrationSearchDBInfo;
