import React from "react";
import qs from "query-string";
import PropTypes from "prop-types";
import navigate from "lib/navigate";
import { inject, observer } from "mobx-react";
import ReactHtmlParser from "react-html-parser";

import { Mutation } from "react-apollo";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import Link from "lib/Link";

import {
  arrangeData,
  encodeJsonString,
} from "components/list/action/action-util";
//import Link from 'next/link';
import View from "components/list/view/View";
import EDSHoldingsComp from "./EDSHoldingsComp";
import { autobind } from "react-decoration";

const addBookmark = gql`
  mutation addBookmark($form: [AddBookmarkItem]) {
    addBookmark(Input: $form) {
      success
      message
    }
  }
`;

const deleteBookmarkBySourceID = gql`
  mutation deleteBookmarkBySourceID($ids: [String]) {
    deleteBookmarkBySourceID(ids: $ids) {
      success
      message
    }
  }
`;

const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

const encodeUrl = (Url) => {
  Url = decodeURIComponent(Url);
  let pos = Url.indexOf("url=");
  if (pos > -1) {
    let { url, query } = qs.parseUrl(Url.substring(pos + 4));
    query.title = encodeURIComponent(query.title);
    Url = `${Url.substring(0, pos + 4)}${[url, qs.stringify(query)].join("?")}`;
  }
  return Url;
};

const iconPath = "/file/images/";
const iconMap = {
  Book: "icon_book.svg",
  "Academic Journal": "icon_journal.svg",
  "Government Document": "icon_government_doc.svg",
  Report: "icon_report.svg",
  "Research Starters": "icon_report.svg",
  eBook: "icon_ebook.svg",
  Image: "icon_report.svg",
  Audio: "icon_report.svg",
  "Video Recording": "icon_report.svg",
  Map: "icon_report.svg",
  "Dissertation/ Thesis": "icon_doctoral.svg",
  Conference: "icon_conference_paper.svg",
  "Electronic Resource": "icon_article.svg",
  Biography: "icon_bio.svg",
  Periodical: "icon_magazine.svg",
  "Periodical Trade Publications": "icon_magazine.svg",
  News: "icon_news.svg",
  Review: "icon_article.svg",
};

@inject("readerStore", "appStore")
@observer
class EDSSearchDisplayComp extends View {
  constructor(props) {
    super(props);

    let { info, data } = props;
    let { userCollectionList } = info;
    let arrangedData = arrangeData(data);
    let {
      An,
      DbId,
    } = arrangedData;
    let isAddBookmark = false;
    if (
      userCollectionList != null &&
      userCollectionList.includes(DbId + "-" + An)
    ) {
      isAddBookmark = true;
    }

    this.state = {
      isAddBookmark,
      abstractDiaplsy: false,
    };
  }

  addFilter = (item, e) => {
    e.preventDefault();
    let params = qs.parseUrl(window.location.href).query;
    let {
      pid = "",
      filterPid = "",
      filterField = [],
      filterValue = [],
      filterValueName = [],
    } = params;
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);
    filterValueName = [].concat(filterValueName || []);

    const { field, value, valueName } = item;
    let matchIndex = filterValue.indexOf(value);
    if (matchIndex == -1) {
      filterField.push(field);
      filterValue.push(value);
      filterValueName.push(valueName);
      params.filterField = filterField;
      params.filterValue = filterValue;
      params.filterValueName = filterValueName;
      navigate([this.props.location.pathname, qs.stringify(params)].join("?"));
      window.scrollTo(0, 0);
    }
  };

  generateFilter = (index, filter, filterValue) => {
    if (filter == "") {
      return [];
    }
    let su = filterValue.split(",");
    let block = filter.split(",").map((s, k) => {
      return (
        <>
          {k > 0 && "、"}
          <a
            tabIndex="0"
            onClick={this.addFilter.bind(this, {
              field: index,
              value: su[k],
              valueName: s,
            })}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.addFilter(
                  {
                    field: index,
                    value: su[k],
                    valueName: s,
                  },
                  e
                );
              }
            }}>
            {s}
          </a>
        </>
      );
    });
    return block;
  };

  reSearch = (keyword) => {
    let params = qs.parseUrl(window.location.href).query;
    delete params.filterField;
    delete params.filterValue;
    delete params.filterValueName;
    delete params.searchInput;
    delete params.searchField;
    delete params.op;
    delete params.pageNo;
    delete params.eid;
    params.searchInput = keyword;
    params.searchField = "TI";
    window.scrollTo(0, 0);
    navigate([this.props.location.pathname, qs.stringify(params)].join("?"));
  };

  @autobind
  addBookmarkAct(addBookmark, values, e) {
    e.preventDefault();
    if (this.props.readerStore.auth) {
      addBookmark({
        variables: {
          form: [{ type: "foreign", values: values }],
        },
      }).then((res) => {
        if (res.data.addBookmark.success) {
          this.setState({ isAddBookmark: true });
        } else {
          alert(
            t("jumperrwd.common.addCollectionFailed") +
              " " +
              res.data.addBookmark.message
          );
        }
      });
    } else this.props.appStore.openLogin();
  }

  @autobind
  deleteBookmarkBySourceIDAct(deleteBookmarkBySourceID, DbId, An, e) {
    e.preventDefault();
    if (this.props.readerStore.auth)
      deleteBookmarkBySourceID({
        variables: { ids: [DbId + "-" + An] },
      }).then((res) => {
        if (res.data.deleteBookmarkBySourceID.success) {
          this.setState({ isAddBookmark: false });
        } else {
          alert(
            t("jumperrwd.common.deleteCollectionFailed") +
              " " +
              res.data.deleteBookmarkBySourceID.message
          );
        }
      });
    else this.props.appStore.openLogin();
  }


  @autobind
  changeIsCollection(isCollection) {
    this.setState({ isAddBookmark: isCollection });
  }

  render() {
    let {
      displayConfig,
      data,
      functionConfigList,
      functionRightConfigList,
      buttonEvent,
      batchConfig,
      rowStyle,
      serialNo,
      auth,
      handleClick,
      displayCheckBox,
      info,
      t,
    } = this.props;

    const arrangedData = arrangeData(data);

    let {
      An,
      DbId,
      Title,
      Author,
      DBLabel,
      Subject,
      PLink,
      BookImg,
      Pubyear,
      CustomLinks = "[]",
      FullTextCustomLinks = "[]",
      Isbn,
      Issn,
      PubType,
    } = arrangedData;
    BookImg = `${iconPath}${iconMap[PubType]}`;
    var values = [];
    data.map((item) => {
      values.push({
        key: item.key,
        value: item.value,
      });
    });

    let isCollection = this.state.isAddBookmark;

    let subjectBlock = []; //this.generateFilter("subject",subject,subjectfilter);
    const yearBlock = []; //this.generateFilter("year",date,yearfilter);
    let authorBlock = []; //this.generateFilter("author",author,authorfilter);
    const journalBlock = []; //this.generateFilter("journal",journal,journalfilter);

    authorBlock = Author.split("^n").filter((v, k) => {
      if (v != "") {
        return (
          <li>
            <a
              href="javascript:;"
              tabIndex="0"
              onClick={() => {
                this.reSearch(v);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.reSearch(v, e);
                }
              }}>
              {v}
            </a>
          </li>
        );
      }
    });

    subjectBlock = Subject.split("^n").map((v, k) => {
      return (
        <li>
          <a
            href="javascript:;"
            tabIndex="0"
            onClick={() => {
              this.reSearch(v);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.reSearch(v, e);
              }
            }}>
            {v}
          </a>
        </li>
      );
    });

    const { abstractDiaplsy } = this.state;
    let abstractClassName = abstractDiaplsy ? "turnicon" : "";
    let CustomLinksArray = [];

    if (CustomLinks != undefined) {
      let CustomLinksArr = JSON.parse(CustomLinks);
      if (CustomLinksArr != null) {
        CustomLinksArray = CustomLinksArray.concat(CustomLinksArr);
      }
    }

    if (FullTextCustomLinks != undefined) {
      let FullTextCustomLinksArr = JSON.parse(FullTextCustomLinks);
      if (FullTextCustomLinksArr != null) {
        CustomLinksArray = CustomLinksArray.concat(FullTextCustomLinksArr);
      }
    }

    let CoustomBlock = CustomLinksArray.map((v, k) => {
      let { Url, Text, Icon } = v;
      Url = encodeUrl(Url);
      return (
        <button
          type="button"
          className="btn_normal"
          tabIndex="0"
          onClick={() => {
            window.open(Url);
          }}>
          {Icon && <img src={Icon} alt={Text} />}
          {Text}
        </button>
      );
    });

    return (
      <>
        <div className={`list_block ${rowStyle}`}>
          <div className="list_num">{serialNo}</div>
          {!this.state.isAddBookmark ? (
            <Mutation mutation={addBookmark}>
              {(addBookmark) => (
                <a
                  href="javascript:;"
                  className="bookmark"
                  tabIndex="0"
                  onClick={this.addBookmarkAct.bind(this, addBookmark, values)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.addBookmarkAct(addBookmark, values, e);
                    }
                  }}>
                  <img
                    src="/file/images/icon/icon_bookmark.svg"
                    alt={t("jumperrwd.common.addfavorites")}
                  />
                </a>
              )}
            </Mutation>
          ) : (
            <Mutation mutation={deleteBookmarkBySourceID}>
              {(deleteBookmarkBySourceID) => (
                <a
                  tabIndex="0"
                  className="bookmark"
                  href="javascript:;"
                  onClick={this.deleteBookmarkBySourceIDAct.bind(
                    this,
                    deleteBookmarkBySourceID,
                    DbId,
                    An
                  )}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.deleteBookmarkBySourceIDAct(
                        deleteBookmarkBySourceID,
                        DbId,
                        An,
                        e
                      );
                    }
                  }}>
                  <img
                    src="/file/images/icon/icon_bookmark_saved.svg"
                    alt={t("jumperrwd.common.delCollection")}
                  />
                </a>
              )}
            </Mutation>
          )}
          <div className="sort_icon">
            <div className="pic">
              <img
                src={BookImg}
                alt={t("jumperrwd.common.book")}
                onError={(e) => {
                  e.target.src = "/file/images/icon_book.svg";
                }}
              />
            </div>
            <div className="sort_name">{PubType}</div>
          </div>
          <div className="list_data">
            <h3>
              {PLink && (
                <a href={encodeUrl(PLink)} target="_BLANK" title={t("jumperrwd.common.openNewWindow")}>
                  {ReactHtmlParser(Title)}
                </a>
              )}
              {!PLink && ReactHtmlParser(Title)}
            </h3>
            <ul>
              {Author && (
                <li>
                  <div className="title">{t("jumperrwd.common.author")}：</div>
                  <ul className="keyword">{authorBlock}</ul>
                </li>
              )}
              {Subject && (
                <li>
                  <div className="title">
                    {t("jumperrwd.common.subjectKeyword")}：
                  </div>
                  <ul className="keyword">{subjectBlock}</ul>
                </li>
              )}
              <li>
                <div className="title">
                  {t("jumperrwd.common.publishDate")}：
                </div>
                <div>{Pubyear}</div>
              </li>
              <li>
                <div className="title">{t("jumperrwd.common.database")}：</div>
                <div>{DBLabel}</div>
              </li>
            </ul>
            <EDSHoldingsComp
              an={An}
              dbid={DbId}
              isbn={Isbn}
              issn={Issn}
              t={t}
            />
            <button
              type="button"
              className="btn_normal"
              tabIndex="0"
              onClick={() => {
                buttonEvent("openDetailModal", [An, DbId, isCollection, this.changeIsCollection]);
              }}>
              {t("jumperrwd.common.detailed")}
            </button>
            {CoustomBlock}
          </div>
        </div>
      </>
    );
  }
}

EDSSearchDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

EDSSearchDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default EDSSearchDisplayComp;
