import navigate from "lib/navigate";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import qs from "query-string";
import React from "react";
import moment from "moment";
import { Interpolate } from "react-i18next";
import autobind from "react-decoration/lib/decorators/functions/autobind";

import DataList from "components/list/DataList";
import {
  DBResourceDetailLightBox,
  EDSSearchDisplayComp,
} from "components/resource";
import SearchRelatedModal from "./SearchRelatedModal";
import EDSPublication from "./EDSPublication";

const edsSearchList = gql`
  query EdsSearch($searchForm: IntegrationSearchForm) {
    EdsSearch(Input: $searchForm) {
      display {
        field
        name
        position
        type
      }
      sort {
        name
        order
        field
        default
      }
      list {
        values {
          ref {
            key
            value
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        filterPid
        searchKeys
        showNext
        showPrevious
        iTotal
        iFilterTotal
        process
        fetchMoreProcess
        total
        iTotal
        pid
        isExpired
        showNext
        fetchMore
        userCollectionList

        pages {
          active
          val
        }
      }
    }
  }
`;

const addBookmark = gql`
  mutation addBookmark($form: [AddBookmarkItem]) {
    addBookmark(Input: $form) {
      success
      message
    }
  }
`;

const deleteBookmarkBySourceID = gql`
  mutation deleteBookmarkBySourceID($ids: [String]) {
    deleteBookmarkBySourceID(ids: $ids) {
      success
      message
    }
  }
`;

const toCurrency = (num) => {
  var parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

@inject("readerStore", "appStore")
@observer
class EDSSearchListComp extends React.Component {
  constructor(props) {
    super(props);
    let params = qs.parseUrl(window.location.href).query;

    let {
      searchInput,
      searchField,
      op,
      db,
      filterField,
      filterValue,
      pid,
      filterDBID,
      fetch,
      pageNo = 1,
      sort,
      mode,
      centralized,
      decentralized,
      limiter,
      publicationId,
    } = params;

    searchInput = searchInput || [];
    searchField = searchField || [];
    filterField = filterField || [];
    filterValue = filterValue || [];
    op = op || [];
    db = db || [];
    limiter = limiter || [];

    if (typeof searchInput === "string") {
      searchInput = [searchInput];
    }
    if (typeof searchField === "string") {
      searchField = [searchField];
    }
    if (typeof filterField === "string") {
      filterField = [filterField];
    }
    if (typeof filterValue === "string") {
      filterValue = [filterValue];
    }
    if (typeof op === "string") {
      op = [op];
    }
    if (typeof db === "string") {
      db = [db];
    }
    if (typeof limiter === "string") {
      limiter = [limiter];
    }

    ////op.splice(0, 0, '');

    this.state = {
      searchForm: {
        searchInput,
        searchField,
        op,
        filterField,
        filterValue,
        pid,
        db,
        filterDBID,
        fetch,
        limit: 20,
        pageNo,
        sort,
        mode,
        limiter,
        publicationId,
        range: this.getDateRange(),
      },
      centralized,
      decentralized,
      isAllTag: false,
      allIds: [],
      allDatas: [],
      modalVisible: false,
      dbID: "",
    };
  }

  getDateRange = () => {
    let params = qs.parseUrl(window.location.href).query;
    let { range = "" } = params;
    let ranges = range.split("/");
    let dateStart = "",
      dateEnd = "";
    dateStart = ranges[0];
    if (ranges.length > 1) {
      dateEnd = ranges[1];
    }

    ranges = [];

    if (moment(dateStart, "YYYY", true).isValid()) {
      dateStart = moment(dateStart, "YYYY", true).format("YYYY-01");
      ranges.push(dateStart);
    } else {
      dateStart = "";
    }

    if (moment(dateEnd, "YYYY", true).isValid()) {
      dateEnd = moment(dateEnd, "YYYY", true).format("YYYY-12");
      ranges.push(dateEnd);
    } else {
      dateEnd = "";
    }

    range = ranges.join("/");

    return range;
  };

  getRefetch = (refetch) => {
    this.refetch = () => {
      refetch();
    };
  };

  componentDidMount() {
    window.aa = this;
  }

  @autobind
  onQueryCompleted(data) {
    let { location } = this.props;
    console.info("onQueryCompleted");
    const { pid, iTotal, total } = data.EdsSearch.info;

    const { searchForm } = this.state;
    //this.filterTotal.innerHTML = `${toCurrency(total)}`;
    this.iTotal.innerHTML = toCurrency(total);

    let params = qs.parseUrl(window.location.href).query;
    if (params.eid != pid) {
      params.eid = pid;
      navigate([location.pathname, qs.stringify(params)].join("?"));
    }
  }
  componentWillReceiveProps(props) {
    /*
    let pid = props.appStore.integrationPid;
    
    if (pid == null){
      pid = "";
    } */
    let params = qs.parseUrl(window.location.href).query;

    let {
      searchInput,
      searchField,
      op,
      filterField,
      filterValue,
      pid,
      db,
      fetch,
      filterDBID,
      pageNo = 1,
      sort,
      mode,
      centralized,
      decentralized,
      limiter,
      publicationId,
    } = params;

    searchInput = searchInput || [];
    searchField = searchField || [];
    op = op || [];
    db = db || [];
    filterField = filterField || [];
    filterValue = filterValue || [];
    limiter = limiter || [];

    if (typeof searchInput === "string") {
      searchInput = [searchInput];
    }
    if (typeof searchField === "string") {
      searchField = [searchField];
    }
    if (typeof op === "string") {
      op = [op];
    }
    if (typeof db === "string") {
      db = [db];
    }
    if (typeof filterField === "string") {
      filterField = [filterField];
    }
    if (typeof filterValue === "string") {
      filterValue = [filterValue];
    }
    if (typeof limiter === "string") {
      limiter = [limiter];
    }
    //op.splice(0, 0, '');

    this.setState({
      searchForm: {
        searchInput,
        searchField,
        op,
        filterField,
        filterValue,
        pid,
        db,
        filterDBID,
        fetch,
        limit: 20,
        pageNo,
        sort,
        mode,
        limiter,
        publicationId,
        range: this.getDateRange(),
      },
    });
    //this.refetch();
  }

  toggleSortDisplay = (e) => {
    if (this.sortOptionList.style.display == "none") {
      this.sortOptionList.style.display = "block";
    } else {
      this.sortOptionList.style.display = "none";
    }
  };

  sort = (e) => {
    let params = qs.parseUrl(window.location.href).query;
    const { sort = "preview" } = params;
    this.sortOptionList.style.display = "none";
    if (e != sort) {
      params.sort = e;
      navigate([this.props.location.pathname, qs.stringify(params)].join("?"));
    }
  };

  toggleFilterBlock = (e) => {
    e.persist();
    let container = e.target.closest(".container");
    container.querySelector(".filter_search").style = "display: block; top:0;";
  };

  openDetailModal = (an, dbID, isCollection, changeIsCollection) => {
    const detailModal = this.props.getEdsDetailModal();
    detailModal.passProps({ an, dbID, isCollection, changeIsCollection }, () => {
      detailModal.popup();
    });
  };

  fetchEvent = (eventName) => {
    return this[eventName] || null;
  };

  render() {
    let { searchForm, centralized, decentralized, dbID } = this.state;

    if (!this.props.display) {
      return null;
    }

    let params = qs.parseUrl(window.location.href).query;
    let { sort = "relevance" } = params;
    const { t, popupDbDetailLightBox } = this.props;

    let sortMap = {
      relevance: `jumper.common.table.relevance`,
      date: `jumper.common.table.year-desc`,
      date2: `jumper.common.table.year-asc`,
    };

    return (
      <>
        <EDSPublication {...this.props} popup={popupDbDetailLightBox} />
        <div className="function_panel">
          <div className="result_amount">
            {t("jumperrwd.common.searchResult")}：
            <div className="search_word"></div>
            <div className="total">
              <Interpolate
                t={t}
                i18nKey="jumperrwd.integration.totalAmount"
                parent={"total"}
                num={
                  <em
                    style={{ color: "#E15706" }}
                    ref={(c) => {
                      this.iTotal = c;
                    }}>
                    0
                  </em>
                }
              />
            </div>
            {/*
            <div className="filter_num">
              <Interpolate
                t={t}
                i18nKey="jumperrwd.integration.currentFilterAmount"
                parent={"filter"}
                num={
                  <em
                    style={{ color: "#E15706" }}
                    ref={(c) => {
                      this.filterTotal = c;
                    }}
                  >
                    0
                  </em>
                }
              />
              </div>*/}
            <a
              tabIndex="0"
              className="relative_subject"
              onClick={() => this.setState({ modalVisible: true })}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({ modalVisible: true });
                }
              }}>
              {t("jumperrwd.common.seeRelatedSubject")}
            </a>
          </div>

          <div className="function_block">
            <div className="option">
              <div className="name">
                <a
                  tabIndex="0"
                  href="javascript:;"
                  onClick={this.toggleSortDisplay}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.toggleSortDisplay(e);
                    }
                  }}>
                  {t("jumperrwd.common.sortType") + " : "}
                  {t(sortMap[sort])}
                </a>
              </div>
              <div
                className="option_list"
                style={{ display: "none" }}
                ref={(c) => {
                  this.sortOptionList = c;
                }}>
                <ul>
                  <li>
                    <a
                      tabIndex="0"
                      className={sort == "relevance" ? "active" : ""}
                      onClick={() => {
                        this.sort("relevance");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.sort("relevance", e);
                        }
                      }}>
                      {t("jumper.common.table.relevance")}
                    </a>
                  </li>
                  <li>
                    <a
                      tabIndex="0"
                      className={sort == "date" ? "active" : ""}
                      onClick={() => {
                        this.sort("date");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.sort("date", e);
                        }
                      }}>
                      {t("jumper.common.table.year-desc")}
                    </a>
                  </li>
                  <li>
                    <a
                      tabIndex="0"
                      className={sort == "date2" ? "active" : ""}
                      onClick={() => {
                        this.sort("date2");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.sort("date2", e);
                        }
                      }}>
                      {t("jumper.common.table.year-asc")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <button
              type="button"
              tabIndex="0"
              className="filter_search_btn"
              onClick={this.toggleFilterBlock}>
              <i className="i_filter"></i>
            </button>
          </div>
        </div>
        <div className="list_all">
          <DataList
            query={edsSearchList}
            variables={{
              searchForm,
            }}
            fetchPolicy={"network-only"}
            displayComp={EDSSearchDisplayComp}
            pagination={"FixedPagination"}
            fetchEvent={this.fetchEvent}
            onQueryCompleted={this.onQueryCompleted}
          />
        </div>
        <SearchRelatedModal
          searchKey={searchForm.searchInput[0]}
          visible={this.state.modalVisible}
          close={() => this.setState({ modalVisible: false })}
          t={t}
        />
      </>
    );
  }
}

export default EDSSearchListComp;
