import { autobind } from "react-decoration";

import LightBox from "components/common/LightBox";
import client from "lib/ApolloClient";

import navigate from "lib/navigate";
import qs from "query-string";
import React from "react";
import { ApolloProvider, Query } from "react-apollo";
import ReactLoading from "react-loading";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";
import ReactDOMServer from "react-dom/server";

import ResourceTagList from "components/resource/ResourceTagList";
import EDSCitation from "components/resource/EDSCitation";
import EDSExport from "components/resource/EDSExport";

import Login from "components/login/Login";

const DisabledStyle = {
  "pointer-events": "none",
  opacity: "0.4",
  cursor: "not-allowed",
  display: "inline-block",
  "-webkit-filter": "grayscale(1)",
};

const EdsSearchDetailGQL = gql`
  query edsdetail($form: IntegrationSearchForm) {
    EdsSearchDetail(Input: $form) {
      header {
        An
        DBID
        DBLabel
        RelevancyScore
        PubType
        PubTypeID
      }
      PLink
      ImageInfo {
        Size
        Target
      }
      CustomLinks {
        Category
        Icon
        Text
        Name
        MouseOverText
      }

      Items {
        Name
        Label
        Data
      }
    }
  }
`;

const sendResourceDetailMail = gql`
  mutation sendResourceDetailMail($form: SendResourceDetailMailForm) {
    sendResourceDetailMail(Input: $form) {
      success
      message
    }
  }
`;

const addBookmark = gql`
  mutation addBookmark($form: [AddBookmarkItem]) {
    addBookmark(Input: $form) {
      success
      message
    }
  }
`;

const deleteBookmarkBySourceID = gql`
  mutation deleteBookmarkBySourceID($ids: [String]) {
    deleteBookmarkBySourceID(ids: $ids) {
      success
      message
    }
  }
`;

const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

@LightBox
class EDSSearchDetailLightBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      infoFunction: "",
      toMail: "",
      cateName: "",
      tagListDisplay: false,
    };
  }

  componentWillReceiveProps(props) {
    if (props.isCollection !== this.state.isCollection) {
      this.setState({ isCollection: props.isCollection });
    }
  }

  setInfoFunction = (infoFunction) => {
    this.setState({ infoFunction }, () => {
      this.scroll_content.scrollTop = this.scroll_content.scrollHeight;
    });
  };

  beforeClose() {
    this.setState({ infoFunction: "", toMail: "" });
  }

  beforeOpen() {
    /*
    let params = qs.parseUrl(window.location.href).query;
    let { filterField = [] , filterValue = [] , filterValueName = []} = params;
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);
    filterValueName = [].concat(filterValueName || []);
    let filtersData = {};
    let checkFilter = {};

    filterField.forEach((field,k)=>{
      let value = filterValue[k];
      let name  = filterValueName[k];
      let key = `${value}`;
      checkFilter[key] = true;
      filtersData[key] = {
        field,
        name
      }
    })
     
    this.setState({
      filtersData,
      checkFilter
    })*/
  }

  addFilter = (item) => {
    const { title, value } = item;
    let { checkFilter, filtersData } = this.state;
    let key = `${value}`;
    if (checkFilter[key] == true) {
      delete checkFilter[key];
    } else {
      checkFilter[key] = true;
      filtersData[key] = {
        field: this.state.index,
        name: title,
      };
    }
    this.setState({ checkFilter: checkFilter });
  };

  submit = (e) => {
    e.preventDefault();
    let params = qs.parseUrl(window.location.href).query;
    let OldFilterValue = [].concat(params.filterValue);
    delete params.filterField;
    delete params.filterValueName;
    delete params.filterValue;
    const { checkFilter, filtersData } = this.state;

    let filterField = [],
      filterValueName = [],
      filterValue = [];
    Object.keys(checkFilter).map((key) => {
      const { field, name } = filtersData[key];
      filterField.push(field);
      filterValueName.push(name);
      filterValue.push(key);
    });

    let hasNew = false;
    filterValue.forEach((of) => {
      hasNew = hasNew || OldFilterValue.indexOf(of) == -1;
    });

    if (hasNew) {
      delete params.filterPid;
      params.filterField = filterField;
      params.filterValueName = filterValueName;
      params.filterValue = filterValue;
      navigate([this.props.location.pathname, qs.stringify(params)].join("?"));
    }
    document.querySelector(".filter_search").style = "";
  };

  @autobind
  print(data, e) {
    e.preventDefault();
    let content = ReactDOMServer.renderToString(
      <>{this.renderLayout(data, false)}</>
    );

    var p = window.open("about:blank");
    setTimeout(() => {
      p.document.write(content);
      setTimeout(() => {
        p.print();
        p.close();
      }, 500);
    }, 500);
  }

  @autobind
  email(data, e) {
    e.preventDefault();
    let { t } = this.props;
    if (this.state.toMail !== "") {
      let content = ReactDOMServer.renderToString(
        <>{this.renderLayout(data, false)}</>
      );

      client.jumperrwdClient
        .mutate({
          mutation: sendResourceDetailMail,
          variables: {
            form: {
              toMail: this.state.toMail,
              content: content,
            },
          },
        })
        .then((res) => {
          if (res.data.sendResourceDetailMail.success) {
            alert(t("jumperrwd.email.sendSuccess"));
            this.setState({ infoFunction: "", toMail: "" });
          } else {
            alert(t("jumperrwd.email.sendFailed"));
          }
        });
    } else {
      alert(t("jumperrwd.email.cannotEmpty"));
    }
  }

  @autobind
  handleToMail(e) {
    this.setState({
      toMail: e.target.value,
    });
  }

  @autobind
  handleCateName(e) {
    this.setState({
      cateName: e.target.value,
    });
  }

  @autobind
  setCateName(cateName, e) {
    e.preventDefault();
    this.setState({
      cateName: cateName,
      tagListDisplay: false,
    });
  }

  @autobind
  showCate() {
    this.setInfoFunction("cate");
  }

  @autobind
  addCate(e) {
    e.preventDefault();
    this.detailCate.addCate();
  }

  @autobind
  addBookmarkAct(data, e) {
    const { Items, PLink, header, ImageInfo } = data.EdsSearchDetail;
    e.preventDefault();
    if (this.props.readerStore.auth) {
      var values = [];
      Items.map((item) => {
        const { Name, Label, Data } = item;
        values.push({
          key: Name,
          value: Data,
        });
      });
      values.push({ key: "dbID", value: header.DBID });
      values.push({ key: "dbLabel", value: header.DBLabel });
      values.push({
        key: "relevancyscore",
        value: header.RelevancyScore,
      });
      values.push({ key: "pubType", value: header.PubType });
      values.push({
        key: "pubTypeID",
        value: header.PubTypeID,
      });
      values.push({ key: "pLink", value: PLink });
      if (ImageInfo != undefined || ImageInfo != null)
        ImageInfo.map((image) => {
          values.push({
            key: "book_" + image.Size,
            value: image.Target,
          });
        });

      client.jumperrwdClient
        .mutate({
          mutation: addBookmark,
          variables: {
            form: [{ type: "foriegn", values: values }],
          },
        })
        .then((res) => {
          if (res.data.addBookmark.success) {
            alert(this.props.t("jumperrwd.common.addCollectionSuccess"));
            this.props.changeIsCollection(true);
            this.setState({ isCollection: true });
          } else {
            alert(
              this.props.t("jumperrwd.common.addCollectionFailed") +
              " " +
              res.data.addBookmark.message
            );
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    } else {
      this._login.wrappedComponent._openLogin();
    }
  }

  @autobind
  deleteBookmarkAct(e) {
    e.preventDefault();
    if (this.props.readerStore.auth)
      client.jumperrwdClient
        .mutate({
          mutation: deleteBookmarkBySourceID,
          variables: {
            ids: [this.props.dbID + "-" + this.props.an],
          },
        })
        .then((res) => {
          if (res.data.deleteBookmarkBySourceID.success) {
            this.props.changeIsCollection(false);
            this.setState({ isCollection: false });
          } else {
            alert(
              this.props.t("jumperrwd.common.deleteCollectionFailed") +
              " " +
              res.data.deleteBookmarkBySourceID.message
            );
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    else this.props.appStore.openLogin();
  }

  @autobind
  translateMessage(cate, msg) {
    let out = "";
    let { t } = this.props;
    let msgTemp = msg.replaceAll(" ", "_");
    if (t(cate + "." + msgTemp.toLowerCase()) !== cate + "." + msgTemp.toLowerCase()) {//有的話就傳
      out = t(cate + "." + msgTemp.toLowerCase());
    } else if (msgTemp.indexOf("_") != -1) { //沒有的話就切最小比對
      let msgs = msgTemp.split("_");
      if (msgs !== null && msgs.length !== 0) {
        let lastterm = "";
        //先做2字詞
        msgs.map((val) => {
          if (lastterm !== "") { //有上一個字
            let pharse2 = lastterm + "_" + val; //組成2字詞
            if (t(cate + "." + pharse2.toLowerCase()) !== cate + "." + pharse2.toLowerCase()) {
              out = out.substring(0, out.lastIndexOf(lastterm)) + t(cate + "." + pharse2.toLowerCase());
            } else {
              out += " " + val;
            }
          } else { //沒有則先放第一個字
            out = val;
          }
          lastterm = val;
        });
        //重來比對單字
        msgs = out.split(" ");
        out = "";
        msgs.map((val) => {
          let tempMessage = "";
          if (out !== "") {
            tempMessage += " ";
          }
          tempMessage += val;
          if (t(cate + "." + val.toLowerCase()) !== cate + "." + val.toLowerCase()) {
            tempMessage = t(cate + "." + val.toLowerCase());
          }
          out += tempMessage;
        });
      }
    } else { //都沒有就回原始的
      out = msg;
    }
    return out;
  }

  renderLayout = (data, isLayout) => {
    const { CustomLinks, Items, PLink, header, ImageInfo } =
      data.EdsSearchDetail;

    let { t } = this.props;

    let layout = [];

    const itemsMap = {};
    const dataBlock = Items.map((v, k) => {
      const { Name, Label, Data } = v;
      itemsMap[Name.toLowerCase()] = v;
      if (!/(?=Title)/gi.test(Name)) {
        return (
          <li>
            <h4>{`${this.translateMessage("jumper.cluster", Label)}：`}</h4>
            <div className="content">{ReactHtmlParser(htmlDecode(Data))}</div>
          </li>
        );
      }
    });

    layout.push(
      <div
        className="scroll_content"
        ref={(c) => {
          this.scroll_content = c;
        }}>
        <section className="detail_top">
          <h3>{htmlDecode(itemsMap["title"].Data)}</h3>
          {/*<img src="/file/images/icon_notice.svg" alt="notice" />*/}
        </section>
        <hr />
        <section>
          <ul className="info_list">{dataBlock}</ul>
        </section>
        {isLayout && (
          <section>
            <h3>{this.props.t("jumperrwd.common.functionList")}</h3>
            <ul className="info_icon">
              {!this.state.isCollection ? (
                <li>
                  <a
                    href="javascript:;"
                    tabIndex="0"
                    onClick={this.addBookmarkAct.bind(this, data)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.addBookmarkAct(data, e);
                      }
                    }}>
                    <div className="img-container">
                      <img
                        src="/file/images/icon/icon_bookmark_normal.svg"
                        alt={this.props.t("jumperrwd.common.addCollection")}
                      />
                    </div>
                    <span className="function_name">
                      {this.props.t("jumperrwd.common.addCollection")}
                    </span>
                  </a>
                </li>
              ) : (
                <li>
                  <a
                    href="javascript:;"
                    tabIndex="0"
                    onClick={this.deleteBookmarkAct.bind(this)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.deleteBookmarkAct(e);
                      }
                    }}>
                    <div className="img-container">
                      <img
                        src="/file/images/icon/icon_bookmark_saved.svg"
                        alt={this.props.t("jumperrwd.common.delCollection")}
                      />
                    </div>
                    <span className="function_name">
                      {this.props.t("jumperrwd.common.delCollection")}
                    </span>
                  </a>
                </li>
              )}
              <li>
                <a
                  tabIndex="0"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setInfoFunction("email");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.setInfoFunction("email");
                    }
                  }}>
                  <div className="img-container">
                    <img
                      src="/file/images/icon/icon_mail.svg"
                      alt={this.props.t("jumperrwd.common.email")}
                    />
                  </div>
                  <span className="function_name">
                    {this.props.t("jumperrwd.common.email")}
                  </span>
                </a>
              </li>
              <li>
                <a
                  tabIndex="0"
                  onClick={this.print.bind(this, data)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.print(data, e);
                    }
                  }}>
                  <div className="img-container">
                    <img
                      src="/file/images/icon/icon_print.svg"
                      alt={this.props.t("jumperrwd.common.print")}
                    />
                  </div>
                  <span className="function_name">
                    {this.props.t("jumperrwd.common.print")}
                  </span>
                </a>
              </li>
              <li>
                <a
                  tabIndex="0"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setInfoFunction("citation");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.setInfoFunction("citation", e);
                    }
                  }}>
                  <div className="img-container">
                    <img
                      src="/file/images/icon/icon_citation.svg"
                      alt={t("jumperrwd.common.quote")}
                    />
                  </div>
                  <span className="function_name">
                    {t("jumperrwd.common.quote")}
                  </span>
                </a>
              </li>
              <li>
                <a
                  tabIndex="0"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setInfoFunction("export");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.setInfoFunction("export", e);
                    }
                  }}>
                  <div className="img-container">
                    <img
                      src="/file/images/icon/icon_export.svg"
                      alt={t("jumperrwd.common.exportDetailFormat")}
                    />
                  </div>
                  <span className="function_name">
                    {t("jumperrwd.common.exportDetailFormat")}
                  </span>
                </a>
              </li>
            </ul>
            {this.state.infoFunction === "email" && (
              <div
                className="showEmail form_inline toolsPanel"
                style={{ display: "block" }}>
                <input
                  name="email"
                  className=""
                  id="email"
                  type="email"
                  title={this.props.t("jumperrwd.common.email")}
                  onChange={this.handleToMail}
                  value={this.state.toMail}
                  placeholder={this.props.t("jumperrwd.cate.enterEmail")}
                />
                <button
                  className="btn btn-submit btn_sendmail"
                  tabIndex="0"
                  onClick={this.email.bind(this, data)}>
                  {this.props.t("jumperrwd.common.submit")}
                </button>
              </div>
            )}
            {this.state.infoFunction === "cate" && (
              <div
                className="showEmail form_inline"
                style={{ display: "block" }}>
                <input
                  name="cate_name"
                  className=""
                  id="cate_name"
                  type="text"
                  title={this.props.t(
                    "jumperrwd.common.enterCollectionTag"
                  )}
                  placeholder={this.props.t(
                    "jumperrwd.cate.enterCollectionTag"
                  )}
                  onChange={this.handleCateName}
                  onFocus={() => {
                    this.setState({ tagListDisplay: true });
                  }}
                  onBlur={() => {
                    setTimeout(
                      () => this.setState({ tagListDisplay: false }),
                      200
                    );
                  }}
                  value={this.state.cateName}
                />
                {this.state.tagListDisplay && (
                  <ResourceTagList
                    resourceType={"databases"}
                    setCateName={this.setCateName}
                  />
                )}
                <button
                  className="btn btn-submit btn_addbookmark"
                  tabIndex="0"
                  onClick={this.addCate.bind(this)}>
                  {this.props.t("hyint.common.table.add")}
                </button>
              </div>
            )}

            {this.state.infoFunction === "citation" && (
              <EDSCitation
                setInfoFunction={this.setInfoFunction}
                scrollContent={this.scroll_content}
                dbid={this.props.dbID}
                an={this.props.an}
                t={t}
              />
            )}

            {this.state.infoFunction === "export" && (
              <EDSExport
                setInfoFunction={this.setInfoFunction}
                dbid={this.props.dbID}
                an={this.props.an}
                t={t}
              />
            )}
          </section>
        )}
      </div>
    );
    return layout;
  };

  render() {
    const { dbID = "", an = "", isCollection = false } = this.props;

    if (dbID == "" || an == "") {
      return "Loading...";
    }

    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={EdsSearchDetailGQL}
            fetchPolicy="network-only"
            variables={{
              form: {
                an,
                dbid: dbID,
              },
            }}>
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <center>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </center>
                );
              if (error) return `Error!${error.message}`;

              return (
                <>
                  <h2 className="title">
                    {this.props.t("jumperrwd.common.bookDetail")}
                  </h2>
                  {this.renderLayout(data, true)}
                </>
              );
            }}
          </Query>
        </ApolloProvider>

        <Login
          ref={(c) => {
            this._login = c;
          }}
          {...this.props}
        />
      </>
    );
  }
}

export default EDSSearchDetailLightBox;
