import React from "react";
import LightBox from "components/common/LightBox";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import ReactLoading from "react-loading";
import qs from "query-string";
import navigate from "lib/navigate";
import withLocation from "lib/withLocation";
import { autobind } from "react-decoration";

const DisabledStyle = {
  "pointer-events": "none",
  opacity: "0.4",
  cursor: "not-allowed",
  display: "inline-block",
  "-webkit-filter": "grayscale(1)",
};

const toCurrency = (num) => {
  var parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

@LightBox
class IntegrationSearchGroupLightBox extends React.Component {
  constructor(props) {
    super(props);

    let state = { ...props };

    if (state.type === "eds") {
      state = this.setMessage(state);
    }
    state.tableSort = "cnt-desc";
    state.oldTitle = props.title;

    this.state = state;
  }

  componentWillReceiveProps(props) {
    if (props.title !== this.state.oldTitle) {
      let state = { ...props };
      if (state.type === "eds") {
        state = this.setMessage(state);
      }
      state.oldTitle = props.title;
      this.setState({ ...state });
    }
  }

  @autobind
  setMessage(state) {
    let { t } = this.props;
    return {
      ...state,
      data: state.data.map((item, key) => {
        const { title } = item;
        let newTitle = title;
        if (item.index === "ContentProvider") {
          let newName = t("jumper.cluster." + title.toLowerCase());
          newName =
            newName !== "jumper.cluster." + title.toLowerCase() &&
            newName !== ""
              ? newName
              : this.translateMessage("jumper.dblabel", title);
          newTitle =
            newName !== "jumper.cluster." + title.toLowerCase() &&
            newName !== ""
              ? newName
              : title;
        } else if (item.index === "Journal") {
          let newName = t("jumper.cluster." + title.toLowerCase());
          newTitle =
            newName !== "jumper.cluster." + title.toLowerCase() &&
            newName !== ""
              ? newName
              : title;
        } else {
          newTitle = this.translateMessage("jumper.cluster", title);
        }
        return { ...item, title: newTitle, oldTitle: title };
      }),
    };
  }

  @autobind
  changeSort(sortType) {
    let { data } = this.state;
    let tableSort = this.state.tableSort;
    if (sortType === "title-asc") {
      data = data.sort((a, b) => (a.title > b.title ? 1 : -1));
    } else if (sortType === "title-desc") {
      data = data.sort((a, b) => (a.title < b.title ? 1 : -1));
    } else if (sortType === "cnt-asc") {
      data = data.sort((a, b) => (parseInt(a.cnt) > parseInt(b.cnt) ? 1 : -1));
    } else if (sortType === "cnt-desc") {
      data = data.sort((a, b) => (parseInt(a.cnt) < parseInt(b.cnt) ? 1 : -1));
    }
    tableSort = sortType;
    this.setState({ data, tableSort });
  }

  beforeOpen() {
    let params = qs.parseUrl(window.location.href).query;
    let { filterField = [], filterValue = [], filterValueName = [] } = params;
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);
    filterValueName = [].concat(filterValueName || []);
    let filtersData = {};
    let checkFilter = {};

    filterField.forEach((field, k) => {
      let value = filterValue[k];
      let name = filterValueName[k];
      let key = `${value}`;
      checkFilter[key] = true;
      filtersData[key] = {
        field,
        name,
      };
    });

    this.setState({
      filtersData,
      checkFilter,
    });
  }

  addFilter = (item) => {
    const { title, value, dbID } = item;
    let { checkFilter, filtersData } = this.state;
    let key = `${value}`;
    if (checkFilter[key] == true) {
      delete checkFilter[key];
    } else {
      checkFilter[key] = true;
      filtersData[key] = {
        field: this.state.index,
        name: title,
        dbID,
      };
    }
    this.setState({ checkFilter: checkFilter });
  };

  submit = (e) => {
    e.preventDefault();
    let params = qs.parseUrl(window.location.href).query;
    let OldFilterValue = [].concat(params.filterValue);
    delete params.filterField;
    delete params.filterValueName;
    delete params.filterValue;
    const { checkFilter, filtersData } = this.state;

    let filterField = [],
      filterValueName = [],
      filterValue = [],
      filterDBID = [];
    Object.keys(checkFilter).map((key) => {
      const { field, name, dbID } = filtersData[key];
      filterField.push(field);
      filterValueName.push(name);
      filterValue.push(key);
      if (this.props.index === "db") {
        filterDBID.push(dbID);
      }
    });

    let hasNew = false;
    filterValue.forEach((of) => {
      hasNew = hasNew || OldFilterValue.indexOf(of) == -1;
    });

    if (hasNew) {
      delete params.filterPid;
      delete params.eid;
      params.filterField = filterField;
      params.filterValueName = filterValueName;
      params.filterValue = filterValue;
      if (this.props.index === "db" && filterDBID.length === 1) {
        params.filterDBID = filterDBID;
      }
      window.scrollTo(0, 0);
      navigate([this.props.location.pathname, qs.stringify(params)].join("?"));
    }
    document.querySelector(".filter_search").style = "";
  };

  @autobind
  translateMessage(cate, msg) {
    let { t } = this.props;
    let out = "";
    if (t(cate + "." + msg.toLowerCase()) !== cate + "." + msg.toLowerCase()) {
      out = t(cate + "." + msg.toLowerCase());
    } else if (msg.indexOf(" ") !== -1) {
      //沒有的話就切最小比對
      let msgs = msg.split(" ");
      if (msgs != null && msgs.length != 0) {
        let lastterm = "";
        //先做2字詞
        msgs.map((val) => {
          if (lastterm !== "") {
            //有上一個字
            let pharse2 = lastterm + " " + val; //組成2字詞
            if (
              t(cate + "." + pharse2.toLowerCase()) !==
              cate + "." + pharse2.toLowerCase()
            ) {
              //有找到2字詞
              out =
                out.substring(0, out.lastIndexOf(lastterm)) +
                t(cate + "." + pharse2.toLowerCase());
            } else {
              out += " " + val;
            }
          } else {
            //沒有則先放第一個字
            out = val;
          }
          lastterm = val;
          return "";
        });
        //重來比對單字
        msgs = out.split(" ");
        out = "";
        msgs.map((val) => {
          out +=
            t(cate + "." + val.toLowerCase()) !== cate + "." + val.toLowerCase()
              ? t(cate + "." + val.toLowerCase())
              : " " + val;
          return "";
        });
      }
    } else {
      //都沒有就回原始的
      out = msg;
    }
    return out;
  }

  render() {
    const { t, index, data, close } = this.props;
    const { checkFilter = {}, filtersData = {} } = this.state;
    return (
      <form onSubmit={this.submit}>
        <div className="check_grp scroll_content">
          <div className="sorting_desc">
            <select
              name="tableSort"
              title={t("jumperrwd.common.sortType")}
              value={this.state.tableSort}
              onChange={(e) => {
                e.preventDefault();
                this.changeSort(e.target.value);
              }}>
              <option value="cnt-asc">{t("jumperrwd.sort.cntAsc")}</option>
              <option value="cnt-desc">{t("jumperrwd.sort.cntDesc")}</option>
              <option value="title-asc">{t("jumperrwd.sort.titleAsc")}</option>
              <option value="title-desc">{t("jumperrwd.sort.titleDesc")}</option>
            </select>
          </div>
          {this.state.data.map((item, key) => {
            const { title, cnt, value, total } = item;
            const disabled = value === "";
            return (
              <>
                <label
                  style={disabled ? DisabledStyle : {}}
                  htmlFor={`${index}-${item.value}`}>
                  <input
                    type="checkbox"
                    title={title}
                    id={`${index}-${item.value}`}
                    name={index}
                    disabled={disabled}
                    value={`${index}-${item.value}`}
                    checked={checkFilter[value] != null}
                    onChange={this.addFilter.bind(this, item)}
                  />
                  {title}
                  <span>
                    ({toCurrency(cnt)}
                    {total != null && `/${toCurrency(total)}`})
                  </span>
                </label>
              </>
            );
          })}
        </div>
        <div className="btn_grp">
          <button
            className="btn btn-reset"
            style={{ float: "none" }}
            tabIndex="0"
            onClick={(e) => {
              e.preventDefault();
              close();
            }}>
            {t("jumperrwd.common.cancel")}
          </button>
          <button
            className="btn btn-submit"
            style={{ float: "none" }}
            tabIndex="0"
            onClick={this.submit}>
            {t("jumperrwd.common.confirm")}
          </button>
        </div>
      </form>
    );
  }
}

export default IntegrationSearchGroupLightBox;
